import React from 'react'
import styled from 'styled-components'
import FormWrap, { handleSubmit } from '../components/formWrap'
import { colors, GreenBtn, md } from '../common'

export default () => (
  <Wrap>
    <h1>Join Us</h1>
    <p>Sign up for our emails for the the latest news and updates!</p>
    <FormWrap css={`margin-top:2rem;`} >
      <input name="form_name" value="join us form" readOnly hidden />
      <label css={`text-transform:uppercase;margin-right:10px;`} htmlFor="join-us">Email Address</label>
      <input aria-label="add email for mailing list" css={`padding:.5rem 1rem;border:0;`} type="email" placeholder="email@address.com" name="email" required />
      <GreenBtn onClick={handleSubmit} css={`margin-left:10px;`}>Subscribe</GreenBtn>
      <input name="subscribe" type="checkbox" checked hidden readOnly/>
    </FormWrap>
  </Wrap>
)

const Wrap = styled.div`
  background-color:${colors.greyDarkest};
  padding: 2rem;
  color: white;
  text-align: center;

  h1 {
    text-transform: uppercase;
    font-size:34px;
    margin-top:0;
  }

  ${md} {
    padding:2rem;
  }
`
