import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import JoinUs from '../sections/joinUs'
import Locations from '../sections/locations'
import { colors } from '../common'

export default () => (
  <Layout>
    <SEO title="Locations" />
    <Locations>
      <h1 css={`text-transform:uppercase;color:${colors.green};`}>Locations & hours</h1>
    </Locations>
    <JoinUs />
  </Layout>
)
