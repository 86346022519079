import React from 'react'
import styled from 'styled-components'
import { colors, md } from '../common'

export default ({ bgColor, children }) => (
  <Wrap color={bgColor}>
    {children}
    <div css={`display:flex;justify-content:space-around;${md}{flex-direction:column;}`}>
    <Card>
      <img src="https://s3.amazonaws.com/underwoods/location-san-marco.jpg" alt="san marco store" />
      <Body>
        <Store>SAN MARCO</Store>
        <br />
        <p>2044 San Marco Blvd.</p>
        <p>Jacksonville, FL 32207</p>
        <br />
        <p><strong>Phone:</strong>904-398-9741</p>
        <p><strong>Corporate Office:</strong> 904-398-4363</p>
        <p><strong>Fax: </strong> 904-398-6082</p>
      </Body>
      <Base>
        <p>Hours of Operation</p>
        <br />
        <p>Monday - Friday: 10AM - 5:30PM</p>
        <p>Saturday: 10AM - 5PM</p>
      </Base>
    </Card>

    <Card>
      <img src="https://s3.amazonaws.com/underwoods/location-ponte-vedra.jpg" alt="ponte vedra beach store" />
      <Body>
        <Store>PONTE VEDRA BEACH</Store>
        <br />
        <p>330 A1A North, Suite 204</p>
        <p>Ponte Vedra Beach, FL 32082</p>
        <br />
        <p><strong>Phone: </strong>904-280-1202</p>
        <p><strong>Fax: </strong>904-280-9972</p>
        <br />
      </Body>
      <Base>
        <p>Hours of Operation</p>
        <br />
        <p>Monday - Friday: 10AM - 6PM</p>
        <p>Saturday: 10AM - 5PM</p>
      </Base>
    </Card>

    <Card>
      <img src="https://s3.amazonaws.com/underwoods/location-avondale.jpg" alt="avondale store" />
      <Body>
        <Store>AVONDALE</Store>
        <br />
        <p>3617 St. Johns Avenue</p>
        <p>Jacksonville, FL 32205</p>
        <br />
        <p><strong>Phone: </strong>904-388-5406</p>
        <p><strong>Fax: </strong>904-387-4836</p>
        <br />
      </Body>
      <Base>
        <p>Hours of Operation</p>
        <br />
        <p>Monday - Friday: 10AM - 5:30PM</p>
        <p>Saturday: 10AM - 5PM</p>
      </Base>
    </Card>
    </div>
  </Wrap>
)

const Card = styled.div`
  background-color: white;
  margin: 2rem;
  font-size:14px;
  display:flex;
  flex-direction:column;
  justify-content:space-between;

  img {
    width: 100%;
  }

  p {
    margin:0;
  }
`

const Base = styled.div`
  background-color: ${colors.greyDarkest};
  color: white;
  padding:2rem;
`

const Body = styled.div`
  padding:2rem;
`

const Store = styled.p`
  text-align: center;
  color: ${colors.green};
`

const Wrap = styled.div`
  background-color: ${ props => props.color === 'green' ? colors.green : colors.greyLight };
  padding:4rem;
  text-align: center;

  ${md} {
    padding: 1rem;
  }
`
